import React from "react";
import Block1 from "./Block1";
import Block2 from "./Block2";
import Block3 from "./Block3";

const Home = () => {
    return (
        <div className="overflow-hidden">
            <Block1 />
            <Block2 />
            <Block3 />
        </div>
    );
};

export default Home;
