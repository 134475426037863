import React from "react";
import TourListBlock1 from "../components/ToursList/TourListBlock1";

const ToursListPage = () => {
    return (
        <div>
            <TourListBlock1 />
        </div>
    );
};

export default ToursListPage;
