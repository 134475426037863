import React from "react";
import TourBlock1 from "../components/TourDetails/Blocks/TourBlock1";
import TourBlock2 from "../components/TourDetails/Blocks/TourBlock2";
import TourDetails from "../components/TourDetails/TourDetails";

const TourDetailsPage = () => {
    return (
        <div>
            <TourDetails />
            {/* <TourBlock1 />
            <TourBlock2 /> */}
        </div>
    );
};

export default TourDetailsPage;
