import React from "react";
import shapka from "../../assets/shapka_banner.webp";
const PrivacyPolicy = () => {
    return (
        <div className="relative">
            <img
                className="absolute brightness-[.60] top-0 left-0 right-0 md:h-[55vh] h-[25vh] w-[100%] object-cover object-bottom"
                src={shapka}
                alt=""
            />
            <div className="content font-montserrat relative z-10">
                <div className="md:h-[55vh] h-[25vh] whiteTextImportant flex justify-center items-center flex-col">
                    <h1 className="text-2xl md:text-4xl text-white mt-6">
                        <>
                            <span className="font-medium text-center text-white">
                                Политика конфиденциальности
                            </span>
                        </>
                    </h1>
                </div>
                <div className="mt-16 font-montserrat">
                    <div>
                        <p className="text-lg font-medium">
                            Данная политика конфиденциальности относится к сайту
                            под доменным именем vnature.kg Эта страница содержит
                            сведения о том, какую информацию мы (администрация
                            сайта) или третьи лица могут получать, когда вы
                            пользуетесь нашим сайтом.
                        </p>
                    </div>
                    <div className="mt-8">
                        <h2 className="text-2xl font-semibold">
                            Данные, собираемые при посещении сайта
                        </h2>
                        <p className="text-lg font-medium mt-4">
                            Персональные данные Персональные данные при
                            посещении сайта передаются пользователем
                            добровольно, к ним могут относиться: имя, фамилия,
                            отчество, номера телефонов, адреса электронной
                            почты, адреса для доставки товаров или оказания
                            услуг, реквизиты компании, которую представляет
                            пользователь, должность в компании, которую
                            представляет пользователь; поля форм могут
                            запрашивать и иные данные.
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Эти данные собираются в целях оказания услуг или
                            продажи товаров, связи с пользователем или иной
                            активности пользователя на сайте, а также, чтобы
                            отправлять пользователям информацию, которую они
                            согласились получать.
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Полученные данные могут использоваться для отправки
                            пользователю текстовой и графической информации, в
                            том числе и рекламного характера, с помощью
                            смс-уведомлений, писем по электронной почте, любых
                            мессенджеров (WhatsApp, Telegram и другие),
                            социальных сетей (ВКонтакте и другие). В случае
                            несогласия пользователя с указанным в настоящем
                            пункте правилом, он может отказаться от получения
                            рассылки перейдя по соответствующей ссылке,
                            имеющейся в каждом письме, а также сообщить об
                            отказе получения информации через мессенджеры,
                            социальные сети.
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Мы не проверяем достоверность оставляемых данных,
                            однако не гарантируем качественного исполнения
                            заказов или обратной связи с нами при некорректных
                            данных.
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Данные собираются имеющимися на сайте формами для
                            заполнения (например, регистрации, оформления
                            заказа, подписки, оставления отзыва, обратной связи
                            и иными).
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Формы, установленные на сайте, могут передавать
                            данные как напрямую на сайт, так и на сайты
                            сторонних организаций (скрипты сервисов сторонних
                            организаций).
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Также данные могут собираться через технологию
                            cookies (куки) как непосредственно сайтом, так и
                            скриптами сервисов сторонних организаций. Эти данные
                            собираются автоматически, отправку этих данных можно
                            запретить, отключив cookies (куки) в браузере, в
                            котором открывается сайт.
                        </p>
                        <br />
                        <br />
                        <strong className="text-lg">
                            Не персональные данные
                        </strong>
                        <p className="text-lg font-medium">
                            Кроме персональных данных при посещении сайта
                            собираются не персональные данные, их сбор
                            происходит автоматически веб-сервером, на котором
                            расположен сайт, средствами CMS (системы управления
                            сайтом), скриптами сторонних организаций,
                            установленными на сайте. К данным, собираемым
                            автоматически, относятся: IP адрес и страна его
                            регистрации, имя домена, с которого вы к нам пришли,
                            переходы посетителей с одной страницы сайта на
                            другую, информация, которую ваш браузер
                            предоставляет добровольно при посещении сайта,
                            cookies (куки), фиксируются посещения, иные данные,
                            собираемые счетчиками аналитики сторонних
                            организаций, установленными на сайте.
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Эти данные носят неперсонифицированный характер и
                            направлены на улучшение обслуживания клиентов,
                            улучшения удобства использования сайта, анализа
                            посещаемости.
                        </p>
                        <br />
                        <br />
                        <strong className="text-lg">
                            Предоставление данных третьим лицам
                        </strong>
                        <p className="text-lg font-medium">
                            Мы не раскрываем личную информацию пользователей
                            компаниям, организациям и частным лицам, не
                            связанным с нами. Исключение составляют случаи,
                            перечисленные ниже.
                        </p>
                        <br />
                        <strong className="text-lg">
                            Данные пользователей в общем доступе
                        </strong>
                        <p className="text-lg font-medium">
                            Персональные данные пользователя могут публиковаться
                            в общем доступе в соответствии с функционалом сайта,
                            например, при оставлении отзывов, может
                            публиковаться указанное пользователем имя, такая
                            активность на сайте является добровольной, и
                            пользователь своими действиями дает согласие на
                            такую публикацию.
                        </p>
                        <br />
                        <strong className="text-lg">
                            По требованию закона
                        </strong>
                        <p className="text-lg font-medium">
                            Информация может быть раскрыта в целях
                            воспрепятствования мошенничеству или иным
                            противоправным действиям; по требованию
                            законодательства и в иных случаях, предусмотренных
                            законом.
                        </p>
                        <br />
                        <strong className="text-lg">
                            Для оказания услуг, выполнения обязательств
                        </strong>
                        <p className="text-lg font-medium">
                            Пользователь соглашается с тем, что персональная
                            информация может быть передана третьим лицам в целях
                            оказания заказанных на сайте услуг, выполнении иных
                            обязательств перед пользователем. К таким лицам,
                            например, относятся курьерская служба, почтовые
                            службы, службы грузоперевозок и иные.
                        </p>
                        <br />
                        <strong className="text-lg">
                            Сервисам сторонних организаций, установленным на
                            сайте
                        </strong>
                        <p className="text-lg font-medium">
                            На сайте могут быть установлены формы, собирающие
                            персональную информацию других организаций, в этом
                            случае сбор, хранение и защита персональной
                            информации пользователя осуществляется сторонними
                            организациями в соответствии с их политикой
                            конфиденциальности.
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Сбор, хранение и защита полученной от сторонней
                            организации информации осуществляется в соответствии
                            с настоящей политикой конфиденциальности.
                        </p>
                        <br />
                        <strong className="text-lg">
                            Как мы защищаем вашу информацию
                        </strong>
                        <p className="text-lg font-medium">
                            Мы принимаем соответствующие меры безопасности по
                            сбору, хранению и обработке собранных данных для
                            защиты их от несанкционированного доступа,
                            изменения, раскрытия или уничтожения, ограничиваем
                            нашим сотрудникам, подрядчикам и агентам доступ к
                            персональным данным, постоянно совершенствуем
                            способы сбора, хранения и обработки данных, включая
                            физические меры безопасности, для противодействия
                            несанкционированному доступу к нашим системам.
                        </p>
                        <br />
                        <strong className="text-lg">
                            Ваше согласие с этими условиями
                        </strong>
                        <p className="text-lg font-medium">
                            Используя этот сайт, вы выражаете свое согласие с
                            этой политикой конфиденциальности. Если вы не
                            согласны с этой политикой, пожалуйста, не
                            используйте наш сайт. Ваше дальнейшее использование
                            сайта после внесения изменений в настоящую политику
                            будет рассматриваться как ваше согласие с этими
                            изменениями.
                        </p>
                        <br />
                        <strong className="text-lg">
                            Отказ от ответственности
                        </strong>
                        <p className="text-lg font-medium">
                            Политика конфиденциальности не распространяется ни
                            на какие другие сайты и не применима к веб-сайтам
                            третьих лиц, которые могут содержать упоминание о
                            нашем сайте и с которых могут делаться ссылки на
                            сайт, а также ссылки с этого сайта на другие сайты
                            сети Интернет. Мы не несем ответственности за
                            действия других веб-сайтов.
                        </p>
                        <br />
                        <strong className="text-lg">
                            Изменения в политике конфиденциальности{" "}
                        </strong>
                        <p className="text-lg font-medium">
                            Мы имеем право по своему усмотрению обновлять данную
                            политику конфиденциальности в любое время. В этом
                            случае мы опубликуем уведомление на главной странице
                            нашего сайта. Мы рекомендуем пользователям регулярно
                            проверять эту страницу для того, чтобы быть в курсе
                            любых изменений о том, как мы защищаем информацию
                            пользователях, которую мы собираем. Используя сайт,
                            вы соглашаетесь с принятием на себя ответственности
                            за периодическое ознакомление с политикой
                            конфиденциальности и изменениями в ней.
                        </p>
                        <br />
                        <p className="text-lg font-medium">
                            Вдохновлялись сайтом{" "}
                            <a
                                className="text-[#00499f]"
                                href="http://russiadiscovery.ru/"
                            >
                                {" "}
                                russiadiscovery.ru
                            </a>{" "}
                        </p>
                        <br />
                        <strong className="text-lg">
                            Как с нами связаться
                        </strong>
                        <p className="text-lg font-medium">
                            Если у вас есть какие-либо вопросы о политике
                            конфиденциальности, использованию сайта или иным
                            вопросам, связанным с сайтом, свяжитесь с нами.
                        </p>
                        <p className="mt-2 text-lg font-medium">
                            Телефон:{" "}
                            <a className="text-[#00499f]" href="/">
                                +996 777 777 777
                            </a>
                        </p>
                        <p className="text-lg font-medium">
                            Email:{" "}
                            <a
                                className="text-[#00499f]"
                                href="mailto:vnature@gmail.com"
                            >
                                vnature@gmail.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
