import React from "react";
import AboutUs from "../components/AboutUs/AboutUs";

const AboutUsPage = () => {
    return (
        <div>
            <AboutUs />
        </div>
    );
};

export default AboutUsPage;
