import React from "react";
import Auth from "../components/Auth/Auth";

const AuthPage = () => {
    return (
        <div>
            <Auth />
        </div>
    );
};

export default AuthPage;
